<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Breeders</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Breeders"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="accent"
              class="mr-2"
              :to="{ name: 'module-paw-breeders-reward-stats' }"
            >
              <v-icon left>mdi-ticket</v-icon>Breeder Stats
            </v-btn>
            <v-btn
              depressed
              color="accent"
              :to="{ name: 'module-paw-breeders-codes' }"
            >
              <v-icon left>mdi-ticket</v-icon>All Discount Codes
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="breeders"
            :items-per-page="-1"
            :footer-props="footerProps"
            no-data-text="No breeders found"
          >
            <template v-slot:item.full_name="{ item }">
              <router-link
                :to="{
                  name: 'module-paw-breeders-individual',
                  params: { customerId: item.customer_id },
                }"
                >{{ item.customer.full_name }}</router-link
              >
            </template>
            <template v-slot:item.kennel_name="{ item }">
              {{ item.customer.last_name }} Ltd
            </template>
            <template v-slot:item.available_credit="{ item }">
              <span class="green--text"> £{{ item.available_credit }}</span>
            </template>
            <template v-slot:item.last_30_days_credits="{ item }">
              £{{ item.creditLastMonth }}</template
            >
            <template v-slot:item.last_12_months_credits="{ item }">
              £{{ item.creditLastYear }}</template
            >
            <template v-slot:item.total_credits="{ item }">
              £{{ item.lifetime_credit }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-paw-breeders-individual',
                      params: { customerId: item.customer_id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View Customer</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "full_name" },
        { text: "Business/Kennel Name", value: "business_name" },
        { text: "Available Credit", value: "available_credit" },
        { text: "Credit Earned - 30 days", value: "last_30_days_credits" },
        { text: "Credit Earned - 12 months", value: "last_12_months_credits" },
        { text: "Lifetime Credit Earned", value: "total_credits" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      footerProps: { "items-per-page-options": [5, 10, 25, 50, 100, -1] },
    };
  },

  computed: {
    breeders() {
      let breeders = this.$store.state.paw["breeders"];

      if (this.searchTerm !== "") {
        breeders = breeders.filter((c) => {
          const name = c.customer.full_name.toLowerCase();
          const email = c.customer.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return breeders;
    },
  },
};
</script>
